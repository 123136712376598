import React, { useCallback, useEffect, useState } from "react";
import { BsFillCreditCard2FrontFill, BsBank } from "react-icons/bs";
import { FaCoins } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { createOrderToken } from "../packages/api/order";
import Spinner from "../components/Spinner";

const getSessionStorage = () => {
  let cart = sessionStorage.getItem("cart");
  if (cart) {
    let newCart: any;
    newCart = sessionStorage.getItem("cart");
    return JSON.parse(newCart);
  } else {
    return [];
  }
};

const Checkout = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(getSessionStorage());
  const [paymentData, setPaymentData] = useState({
    message: "",
    order_ids: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { amount } = userData?.reduce(
    (total: any, item: any) => {
      const { points } = item;
      total.amount += points;
      return total;
    },
    {
      amount: 0,
    }
  );

  // handle payment checkout
  const handleCheckout = async (e: any) => {
    e.preventDefault();
    setIsModalOpen(true);
    createOrderToken().then((res) => {
      const response = res.data.data;
      setPaymentData(response);
    });
  };

  const removeCart = () => {
    sessionStorage.removeItem("cart");
  };

  const [isLoading, setIsLoading] = useState(false);
  const setLoader = ()=>{
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  return (
    <section className="h-screen bg-[#FAFAFA]">
      <div className=" py-7 px-5 lg:w-[40vw] lg:mx-auto ">
        <h1 className="font-semibold text-3xl mb-10 ">Checkout</h1>
        <form className="" onSubmit={handleCheckout}>
          <h2 className="font-semibold text-2xl pb-5 ">Payment Method</h2>
          <div className="bg-gray-300 shadow-lg rounded-md">
            {/* creditcard  */}
            <div className="flex  items-center space-x-2 text-xl py-4 px-3">
              <input type="radio" name="payment" className="accent-black" />
              <BsFillCreditCard2FrontFill className="h-5 text-gray-800" />
              <h3>Credit/Debit Card</h3>
            </div>
            {/* upi  */}
            <div className="flex  items-center space-x-2 text-xl py-4 px-3">
              <input type="radio" name="payment" className="accent-black" />

              <img
                src="https://www.kvgbank.com/images/gallery/upi.png"
                alt="upi"
                className="h-4"
              />
              <h3>UPI</h3>
            </div>
            {/* net banking  */}
            <div className="flex  items-center space-x-2 text-xl py-4 px-3">
              <input type="radio" name="payment" className="accent-black" />
              <BsBank />
              <h3>Net Banking</h3>
            </div>
          </div>
          <div className=" shadow-lg rounded-md mt-10 px-2 py-2 flex flex-col bg-gray-300">
            <div className="flex justify-between font-semibold text-lg">
              <h2>Total Points :</h2>
              <p className="flex items-center">
                <FaCoins className="text-[#E1B530] mr-1" />
                {amount}
              </p>
            </div>
            <p className="text-xs my-3">
              By completing your purchase you agree to these{" "}
              <span className="text-blue-500 cursor-pointer">
                Terms of Service
              </span>
              .
            </p>

            <button className=" py-3 bg-black my-2 text-white" type="submit" onClick={setLoader}>
              Proceed
            </button>
          </div>
        </form>
      </div>
      <div
        className={`${
          isModalOpen
            ? " h-screen w-screen fixed top-0 flex items-center justify-center"
            : "hidden"
        }`}
      >
        <div
          className={`${
            isModalOpen
              ? "h-screen w-screen bg-black opacity-50  fixed top-0"
              : "hidden"
          }`}
        ></div>
        <div className="border z-40 h-40 border-gray-500 bg-white  w-80 rounded-md text-lg font-medium text-black text-center py-5 ">
          {isLoading ? (  <>
            <Spinner/>
            <h2 className="text-center my-8  text-sm">
               Processing your payment . . .
            </h2>
          </>
          ) : (
            <div className="">
              <div>
                <BsCheckCircleFill className=" mx-auto text-3xl text-green-400" />
              </div>
              <h2 className="my-3 ">Thank you for subscribing</h2>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  navigate("/");
                  removeCart();
                }}
                className="bg-black bg-opacity-100 rounded text-white  py-1 px-10"
              >
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Checkout;
