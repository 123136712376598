import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

import { motion } from "framer-motion";

const ScrollSection = ({ data, title }) => {
  return (
    <section className=" py-5 pl-14 space-y-4">
      <h1 className="font-semibold text-2xl cursor-pointer  inline hover:text-[#6360FF]">{title}</h1>
      <Swiper   breakpoints={{
    
    410: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 2,
    },
    
    980: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 8,
    },
  }} slidesPerView={8} spaceBetween={20} navigation={true} modules={[Navigation]} className="mySwiper text-black var( --swiper-theme-color)  swipper " >
       
          {data.map((item, i) => {
            return (
              <SwiperSlide className="hover:scale-125 duration-500 cursor-pointer swiper-scroll rounded-lg ">
                <div key={i} className=" h-[180px]  w-[195] rounded-lg ">
                  <img
                    src={item.image}
                    alt={item.movie}
                    className=" object-cover h-full w-full rounded-lg"
                  />
                </div>
              </SwiperSlide>
            );
          })}
       
      </Swiper>
    </section>
  );
};

export default ScrollSection;
