//  Navbar links 
export const navData = [
    {
      name:"TV",
      url:"/"
    },
    {
      name:"Movies",
      url:"/"
    },
    {
      name:"Sports",
      url:"/"
    },
    {
      name:"Disney++",
      url:"/"
    },
]

// Hero section data 
export const heroData = [
  {
    title:"Bohot pyaar karte hai",
    type:"StarPlus - Hindi - Comedy",
    details:` Anandibaa's worst nightmare comes true when her son marries Emily, a
    foreigner. Fun-filled chaos ensues as Emily tries to live up to the
    notion of perfect bahu`,
    image:"https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/2503/1292503-h-ea85ed8e1011"
  },
  {
    title:"Jiji Maa",
    type:"StarPlus - Hindi - Comedy",
    details:` Anandibaa's worst nightmare comes true when her son marries Emily, a
    foreigner. Fun-filled chaos ensues as Emily tries to live up to the
    notion of perfect bahu`,
    image:"https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/803/220803-h"
  },
  {
    title:"Bed Stories",
    type:"StarPlus - Hindi - Comedy",
    details:` Anandibaa's worst nightmare comes true when her son marries Emily, a
    foreigner. Fun-filled chaos ensues as Emily tries to live up to the
    notion of perfect bahu`,
    image:"https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/3789/1293789-h-72439e3d3625"
  },
  {
    title:"Anandibaa Aur Emily",
    type:"StarPlus - Hindi - Comedy",
    details:` Anandibaa's worst nightmare comes true when her son marries Emily, a
    foreigner. Fun-filled chaos ensues as Emily tries to live up to the
    notion of perfect bahu`,
    image:"https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/9663/1289663-h-67067c569050"
  },
  {
    title:"Vikram",
    type:"StarPlus - Hindi - Action",
    details:` Anandibaa's worst nightmare comes true when her son marries Emily, a
    foreigner. Fun-filled chaos ensues as Emily tries to live up to the
    notion of perfect bahu`,
    image:"https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/6237/1296237-h-5662015305e4"
  },
]


// footer data 
export const footerData = [
  {
    name:"About",
    url:"/"
  },
  {
    name:"Terms Of Use",
    url:"/"
  },
  {
    name:"Privacy Policy",
    url:"/"
  },
  {
    name:"FAQS",
    url:"/"
  },
  {
    name:"Feedback",
    url:"/"
  },
  {
    name:"Careers",
    url:"/"
  },
]

// trending section data 
export const trending = [
  {
    image:"https://images.ottplay.com/images/masoom-958.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://m.media-amazon.com/images/M/MV5BZmQ3OTZkNDUtNTU0Mi00ZjE4LTgyNTUtY2E4NWRmNDUxMzkyXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/images/masoom-958.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://m.media-amazon.com/images/M/MV5BZmQ3OTZkNDUtNTU0Mi00ZjE4LTgyNTUtY2E4NWRmNDUxMzkyXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/images/masoom-958.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://m.media-amazon.com/images/M/MV5BZmQ3OTZkNDUtNTU0Mi00ZjE4LTgyNTUtY2E4NWRmNDUxMzkyXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/images/masoom-958.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://m.media-amazon.com/images/M/MV5BZmQ3OTZkNDUtNTU0Mi00ZjE4LTgyNTUtY2E4NWRmNDUxMzkyXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/images/masoom-958.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://m.media-amazon.com/images/M/MV5BZmQ3OTZkNDUtNTU0Mi00ZjE4LTgyNTUtY2E4NWRmNDUxMzkyXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/images/masoom-958.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://m.media-amazon.com/images/M/MV5BZmQ3OTZkNDUtNTU0Mi00ZjE4LTgyNTUtY2E4NWRmNDUxMzkyXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/images/masoom-958.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://m.media-amazon.com/images/M/MV5BZmQ3OTZkNDUtNTU0Mi00ZjE4LTgyNTUtY2E4NWRmNDUxMzkyXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_FMjpg_UX1000_.jpg",
    about:"",
    movie:"dr strange"
  },
 
  
]

export const popularShow = [
  {
    image:"https://images.ottplay.com/posters/Anupama_2020_poster_1_523.jpeg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.justwatch.com/poster/149666176/s718/yeh-rishta-kya-kehlata-hai.%7Bformat%7D",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/posters/Anupama_2020_poster_1_523.jpeg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.justwatch.com/poster/149666176/s718/yeh-rishta-kya-kehlata-hai.%7Bformat%7D",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/posters/Anupama_2020_poster_1_523.jpeg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.justwatch.com/poster/149666176/s718/yeh-rishta-kya-kehlata-hai.%7Bformat%7D",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/posters/Anupama_2020_poster_1_523.jpeg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.justwatch.com/poster/149666176/s718/yeh-rishta-kya-kehlata-hai.%7Bformat%7D",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/posters/Anupama_2020_poster_1_523.jpeg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.justwatch.com/poster/149666176/s718/yeh-rishta-kya-kehlata-hai.%7Bformat%7D",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/posters/Anupama_2020_poster_1_523.jpeg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.justwatch.com/poster/149666176/s718/yeh-rishta-kya-kehlata-hai.%7Bformat%7D",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.ottplay.com/posters/Anupama_2020_poster_1_523.jpeg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://images.justwatch.com/poster/149666176/s718/yeh-rishta-kya-kehlata-hai.%7Bformat%7D",
    about:"",
    movie:"dr strange"
  },
 
  
]

export const recommendedMovies = [
  {
    image:"https://upload.wikimedia.org/wikipedia/en/0/07/Baaghi_Hindi_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/3/3f/Tanaji_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/0/07/Baaghi_Hindi_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/3/3f/Tanaji_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/0/07/Baaghi_Hindi_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/3/3f/Tanaji_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/0/07/Baaghi_Hindi_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/3/3f/Tanaji_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/0/07/Baaghi_Hindi_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/3/3f/Tanaji_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/0/07/Baaghi_Hindi_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/3/3f/Tanaji_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/0/07/Baaghi_Hindi_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
  {
    image:"https://upload.wikimedia.org/wikipedia/en/3/3f/Tanaji_film_poster.jpg",
    about:"",
    movie:"dr strange"
  },
 
  
]

