import axios from "axios"
import { env } from "../../../constants/env"
const orderURL = `${env.BASE_URL}/abs/client/catalogue/order`
const orderData = {
  "user": {
    "first_name": "Jhon",
    "last_name": "Doe",
    "phone": "+919999999999",
    "email": "test@mail.com",
    "address_1": "4417 S Kansas Ave",
    "address_2": "West Virginia",
    "zipcode": "53235",
    "city": "St Francis",
    "state": "West Virginia",
    "country": "United States"
  },
  "rewards": [1, 2, 3, 4, 5],
  "upoid": "UUID32489"
}
export const createOrderToken = async () => {
  try {
    const response = await axios.post(orderURL, orderData, {
      headers: {
        "X-API-KEY": env.X_API_KEY,
        "X-API-SECRET": env.X_API_SECRET
      }
    })

    return response
  } catch (e) {
    throw e
  }
}