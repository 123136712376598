import { createContext, useContext, useEffect, useState } from "react";

export const AppContext = createContext<any>(null)

export const AppProvider = (props:{children:JSX.Element})=>{
 const [purchase,setPurchase] = useState<any>()

// useEffect(()=>{
//     localStorage.setItem("points", JSON.stringify(purchase * 4))
// },[purchase])

    return <AppContext.Provider value={{purchase,setPurchase}}>
          {props.children}
    </AppContext.Provider>
}

export const useGlobalContext = ()=>{
    return useContext(AppContext)
}