import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FaCoins } from "react-icons/fa";
import { useGlobalContext } from "../context/context";
const subsData = [
  {
    type: "BASIC",
    monthlyPrice: "500",
    yearlyPrice: "6000",
    features: [
      "Custom fields (Basic)",
      "Web forms (Basic)",
      "Workflows (Basic)",
      "Reports and Dashboards (Basic)",
      "Slack Integration",
      "Roles and Profiles",
      "10,000 Records",
    ],
  },
  {
    type: "STANDARD",
    monthlyPrice: "720",
    yearlyPrice: "7200",
    features: [
      "Scoring Rules",
      "Email Insights",
      "Workflows Conversion",
      "Tags and Groups",
      "Web forms",
      "Custom, Unique Fields",
      "Custom Reports and Dashboards",
      "10,000 Records",
    ],
  },
  {
    type: "PROFESSIONAL",
    monthlyPrice: "1200",
    yearlyPrice: "9600",
    features: [
      "Custom fields (Basic)",
      "Web forms (Basic)",
      "Workflows (Basic)",
      "Reports and Dashboards (Basic)",
      "Slack Integration",
      "Roles and Profiles",
      "10,000 Records",
    ],
  },
];

const Subscription = () => {
  const {purchase,setPurchase} = useGlobalContext()
  const [toggle, setToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <section className=" bg-[#050505] text-white">
        <div className=" py-10">
          <h1 className="text-center font-medium text-xl md:text-2xl">
            Get 15-day free trail subscription{" "}
          </h1>
          <p className="text-center text-xs my-3">
            Find simple plans for comprehensive automation
          </p>
          <div className=" flex text-sm w-[50vw] items-center justify-center mx-auto">
            <span>Monthly</span>
            <div
              className={`h-4 m-3 bg-gray-400  w-12 rounded-xl flex items-center relative ${
                toggle ? `justify-start` : `justify-end`
              }`}
              onClick={() => setToggle(!toggle)}
            >
              <div className={`${toggle ? "bg-white absolute m-1 w-6 rounded-full h-6" : "bg-[#6360FF] absolute m-1 w-6 rounded-full h-6" } `}></div>
            </div>
            <span>Yearly</span>
          </div>
        </div>
        <div className="py-2 flex flex-col lg:flex-row justify-center  w-full   bg-[#050505]  text-[#FAFAFA] items-center ">
          {subsData.map((item, i) => {
            const { type, monthlyPrice, features, yearlyPrice } = item;

            return (
              <div
                key={i}
                className="px-5 w-max py-2 rounded-md flex-col  justify-center m-4 bg-[#0f0f0f] shadow-lg h-[450px] "
              >
                <h2 className="text-center font-medium text-xl">{type}</h2>
                <div className="rounded-md bg-[#6360FF] w-7 h-1 mt-1 mx-auto"></div>
                <h2 className=" flex items-center justify-center text-center mt-3 text-sm">
                  <BiRupee className="text-xl mb-2" />{" "}
                  <span className="text-4xl font-medium">{`${
                    toggle ? monthlyPrice : yearlyPrice
                  }`}</span>{" "}
                  <span className="mt-3 pl-1">{`${
                    toggle ? "/month" : "/year"
                  }`}</span>
                </h2>
              <div className="h-60  pt-4">

                {features.map((feature, f) => {
                  return (
                    <div
                    key={f}
                    className="leading-loose text-sm  tracking-wide flex items-center"
                    >
                      <AiOutlineCheck className="mx-2 text-[#6360FF]" />
                      <h1 className="">{feature}</h1>
                    </div>
                  );
                })}
                </div>
                  <div className="flex justify-center  mt-10  ">
                  <button
                    className={`py-2 px-3 border border-[#6360FF] rounded  w-full font-medium bg-[#6360FF] hover:bg-black hover:text-white text-sm duration-300`}
                    onClick={() => {
                      setIsModalOpen(true)
                      if (toggle) {
                        setPurchase(monthlyPrice)
                      }
                      else if (!toggle) {
                        setPurchase(yearlyPrice)
                      }
                        
                    }}
                  >
                    GET STARTED
                  </button>
                </div>
              </div>
            );
          })}
          
        </div>
{/* modal start  */}
<div
        className={`${
          isModalOpen
            ? " h-screen w-screen fixed top-0 flex items-center justify-center"
            : "hidden"
        }`}
      >
        <div
         onClick={()=>setIsModalOpen(false)}
          className={`${
            isModalOpen
              ? "h-screen w-screen bg-black opacity-50  fixed top-0 "
              : "hidden"
          }`}
        ></div>
        <div className=" border z-40 h-44 border-gray-500 bg-white  w-80 rounded-md text-lg font-medium text-black text-center py-2 space-y-2">
          <h2 className="">Congratulations ! ! !</h2>
          <h4 className=" flex items-center justify-center">You got <FaCoins className=" text-[#E1B530] ml-2 mr-1 "/> {purchase * 4} points</h4>
          <p className="text-xs text-gray-400">We have added points to your wallet</p>
          <h4 className="text-xs text-gray-400">Redeem it now</h4>
          <button onClick={()=>{
            window.localStorage.setItem('points',JSON.stringify(purchase * 4))
            navigate("/store")
            }} className=" rounded px-5 py-1 border border-[#6360FF] bg-[#6360FF] text-white">Okay</button>
        </div>
      </div>


{/* modal end  */}
        
        
      </section>
    </>
  );
};

export default Subscription;
// #6360FF
