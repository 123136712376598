import React from "react";
import { heroData } from "../utils/data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";



const Hero = () => {
  return (
    <Swiper spaceBetween={50} slidesPerView={"auto"} loop={true} autoplay={{
      delay: 5000,
      disableOnInteraction: false,
    }}  navigation={true} modules={[Autoplay,Navigation]} speed={1000} className="swiper-hero" >

      {heroData.map((item, i) => {
          const { title, details, image, type } = item;
        return (
          <SwiperSlide
            key={i}
            className=" flex  relative  text-white  lg:w-[95vw] overflow-visible shadow-lg rounded-xl mx-auto items-center lg:pl-5 lg:space-x-8 bg-cardColor swiper-hero-slide "
            >
            {/* left  */}
            <div className=" absolute z-30 pl-3 pt-16 bg-black  opacity-60 lg:bg-transparent lg:opacity-100 lg:pt-0 h-full lg:static lg:block lg:h-60 w-[35vw]  font-medium space-y-2 text-lg  ">
              <h1 className=" text-3xl font-bold">{title}</h1>
              <div className="text-gray-100 lg:text-gray-400">
                <span>{type}</span>
              </div>
              <div className="text-gray-100 lg:text-gray-400">{details}</div>
            </div>

            {/* right  */}
            <div className=" lg:w-[65vw] h-[28rem] opacity-90 ">
              <img
                src={image}
                alt={title}
                className="h-full w-full object-cover rounded-xl "
                />
            </div>
          </SwiperSlide>
              
        );
      })}
    </Swiper>
  );
};

export default Hero;
