import {
  faCoins,
  faExclamationTriangle,
  faFilter,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Filter from "../components/filter";
import Reward from "../components/reward";
import { getRewards } from "../packages/api/store/product";
import htmlParser from "html-react-parser";
import { IReward } from "../interfaces/IRewards";
import Modal from "../components/Modal";
import { InView } from "react-intersection-observer";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useGlobalContext } from "../context/context";

const getSessionStorage = () => {
  let cart = sessionStorage.getItem("cart");
  if (cart) {
    let newCart: any;
    newCart = sessionStorage.getItem("cart");
    return JSON.parse(newCart);
  } else {
    return [];
  }
};

const getPoints = ()=>{
  let points = localStorage.getItem("points");
  if (points) {
    let newPoints: any;
    newPoints = localStorage.getItem("points");
    return JSON.parse(newPoints);
  } else {
    return 0;
  }
}

const Home = () => {
  const {purchase} = useGlobalContext()
  const navigate = useNavigate();
  const [view, setView] = useState<{
    status: boolean;
    value: IReward | any;
    isInCart: boolean;
  }>({ status: false, value: undefined, isInCart: false });
  const [rewards, setRewards] = useState<any[]>([]);
  const [cart, setCart] = useState<any[]>(getSessionStorage());
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [[min, max], setPriceRange] = useState([0, 0]);
  const [brand, setBrand] = useState([]);
  const [page, setPage] = useState(1);
  const [blockLoading, setBlockLoading] = useState({
    block: true,
    type: "prev",
  });
  const [userData, setUserData] = useState<any>({});
  const [totalPoints, setPoints] = useState(getPoints());
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    status: true,
    message: "",
    type: "",
  });
  const [count, setCount] = useState(0);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);

  const [error, setError] = useState({ status: false, type: "", message: "" });

  const [loadingNewProducts, setLoadingNewProducts] = useState(false);

  const [searchParams] = useSearchParams();


useEffect(()=>{
  localStorage.setItem("points",JSON.stringify(totalPoints))
},[totalPoints])

  const updateCart = (id: number, points: number) => {
    const selectedReward = rewards.filter((el) => el.id === id)[0];
    const isPresent = cart.filter((el) => el.id === id);
    if (totalPoints >= points) {
      if (isPresent.length <= 0) {
        setPoints((e:any) => e - points);
        setCart((prev) => {
          sessionStorage.setItem(
            "cart",
            JSON.stringify([...prev, selectedReward])
          );
          return [...prev, selectedReward];
        });
      }
    } else {
      setModalOpen(true);
      setErrorMessage({
        status: true,
        message: "Not enough points.",
        type: "error",
      });
    }
  };
  const deleteItem = (id: number, points: number) => {
    const isPresent = cart.filter((el) => el.id === id);
    if (isPresent.length > 0) {
      setPoints((e:any) => e + points);
      const newItems = cart.filter((el) => el.id !== id);
      sessionStorage.setItem("cart", JSON.stringify(newItems));
      setCart(newItems);
      setView({ status: view.status, value: view.value, isInCart: false });
    }
  };

  const loadProducts = async (page: number, filters?: any) => {
    if(page > 0){
    await getRewards(page, filters || null)
      .then((res) => {
        const response = res.data.data;
        setBlockLoading({ block: response.links.next === null, type: "next" });
        setCount(response?.total_count || response?.results?.length || 0);
        setRewards((r) =>
          page !== 1 ? [...r, ...response.results] : response.results
        );
        setError({ status: false, message: error.message, type: error.type });
        setIsLoading(false);
        setFilterLoading(false);
        setLoadingNewProducts(false)
      })
      .catch((error) => {
        setPage(page - 1);
        if (error?.response?.data?.data?.message === "Signature has expired") {
          setError({
            status: true,
            type: "",
            message:
              "Token expired. Please try again" || "Something went wrong",
          });
        } else {
          setError({
            status: true,
            type: "api",
            message: "Something went wrong",
          });
        }
        setIsLoading(false);
        setFilterLoading(false);
        setLoadingNewProducts(false)
      });
    }
  };

  const redeemRewards = () => {
    navigate("/checkout");
  };

  useEffect(() => {
    setIsLoading(true);
    if (rewards.length === 0) {
      loadProducts(1);
      
    }
    if(window && window !== null) {
      setPoints(getPoints()); 
    }
  }, []);

  //Pagination Hook

  useEffect(() => {
    if (filterLoading) {
      loadProducts(page, {
        categories: category,
        brands: brand,
        points_range: [min, max],
      });
    } else if(page > 1) {
      
      
      loadProducts(page);
    }
  }, [page]);

  useEffect(() => {
    if (view.status) {
      const isPresent = cart.filter((el) => el.id === view.value.id);
      if (isPresent.length > 0) {
        setView({ status: view.status, value: view.value, isInCart: true });
      } else {
        setView({ status: view.status, value: view.value, isInCart: false });
      }
    }
  }, [cart, view.value]);

  useEffect(() => {
    if (brand.length > 0 || category.length > 0 || min > 0 || max > 0) {
      setFilterLoading(true);
      setPage(1);
      setBlockLoading({ block: false, type: blockLoading.type });
      console.log('loading new p');
      loadProducts(1, {
        categories: category,
        brands: brand,
        points_range: [min, max],
      });
    }
  }, [brand, category, min, max]);

  return (
    <>
      {isLoading ? (
        <div
          className={`fixed w-screen h-screen z-[400]  top-0 right-0 flex items-center justify-center flex-col bg-[#333333]`}
        >
          <div>
            {error.status ? (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color="#fff"
                size="3x"
              />
            ) : (
              <FontAwesomeIcon spin icon={faSpinner} color="#fff" size="3x" />
            )}
          </div>
          <h1 className="text-white my-2 text-xl font-medium">
            {error.status ? error.message : "Getting rewards..."}
          </h1>
        </div>
      ) : (
        <main
          className={` md:grid-cols-[20%_78%] h-screen overflow-y-hidden bg-[#050505] transition-[display] w-screen overflow-x-hidden  grid gap-[1%] p-[1%] justify-between `}
        >
          <div className="my-1 rounded drop-shadow bg-white py-1 px-2  md:hidden flex items-center justify-between border border-blue-900  ">
            <div className="rounded-full w-[47.5%] text-sm font-medium flex items-center justify-center">
              <h3>Balance:</h3>
              <div className="mx-1">
                <FontAwesomeIcon color="#E1B530" icon={faCoins} />
                <span className="mx-2">{totalPoints || 0}</span>
              </div>
            </div>
            <div className="w-[2px] bg-[#000000]/25 h-full "></div>
            <div className="flex w-[47.5%]  text-[#000000]/50 items-center justify-center ">
              <FontAwesomeIcon icon={faFilter} />
              <span className="mx-2">Filters</span>
            </div>
          </div>
          <div className="md:inline-block hidden opacity-50 ">
            <Filter
              showError={(e) =>
                setError({ status: e, type: "", message: "Invalid Token" })
              }
              filterLoading={filterLoading}
              setFilterLoading={(e: boolean) => setFilterLoading(e)}
              cart={cart}
              view={view}
              setBrand={(e: any) => setBrand(e)}
              setCategory={(e: any) => setCategory(e)}
              setPriceRange={(e: any) =>
                setPriceRange([parseInt(e.min), parseInt(e.max)])
              }
              brand={brand}
              category={category}
              min={min}
              max={max}
            />{" "}
            : <></>
          </div>
          <div
            className={`${cart.length > 0 ? "h-[75vh]" : "h-[96vh]"
              } hide-scroll-bar h-full transition-all  text-[#FAFAFA] rounded shadow  bg-[#050505] shadow-slate-900/10 p-3   `}
          >
            <div className="flex items-center justify-between  mb-2">
              <h2 className="text-xl font-semibold">
                Rewards{" "}
                <span className="text-xs font-normal text-[#FAFAFA]">
                  ({rewards.length} items)
                </span>
              </h2>
              <div className="rounded-full text-sm font-medium hidden md:flex items-center">
                <h3>Balance:</h3>
                <div className="mx-1">
                  <FontAwesomeIcon color="#E1B530" icon={faCoins} />
                  <span className="mx-2">{totalPoints || 0}</span>
                </div>
              </div>
            </div>
            {rewards.length > 0 ? (
              <div
                style={{
                  height: `calc(${cart.length > 0 ? "75vh" : "96vh"} - 50px)`,
                }}
                className={` md:grid-cols-3 scrollbar-none lg:grid-cols-4 overflow-y-auto transition-[display] w-full grid grid-cols-2 gap-4 `}
              >
                {rewards.map((item, index) => {
                  if (index === rewards.length - 1) {
                    return (
                      <InView
                        as="div"
                        key={index}
                        onChange={(inView, entry) => {
                          if (inView && !loadingNewProducts) {
                            setLoadingNewProducts(true);
                            if (!blockLoading.block) {
                              setPage(page + 1);
                            }
                          }
                        }}
                      >
                        <Reward
                          key={index}
                          data={item}
                          onViewClick={() =>
                            setView({
                              status: true,
                              value: item,
                              isInCart: view.isInCart,
                            })
                          }
                          addToCart={() => updateCart(item.id, item.points)}
                          isInCart={
                            cart.filter((el) => el.sku === item.sku).length > 0
                          }
                        />
                      </InView>
                    );
                  } else {
                    return (
                      <Reward
                        key={index}
                        data={item}
                        onViewClick={() =>
                          setView({
                            status: true,
                            value: item,
                            isInCart: view.isInCart,
                          })
                        }
                        addToCart={() => updateCart(item.id, item.points)}
                        isInCart={
                          cart.filter((el) => el.sku === item.sku).length > 0
                        }
                      />
                    );
                  }
                })}
              </div>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <h3 className="text-2xl font-semibold">No products found</h3>
              </div>
            )}
          </div>
          {cart.length > 0 ? (
            <div className="pb-2  bg-[#050505]   text-[#FAFAFA] rounded  shadow shadow-slate-900/10  flex flex-col col-span-full  ">
              <div className="w-screen flex justify-between items-center  p-3 pb-3 ">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={5}
                  className=" z-40  flex flex-row items-center  scrollbar-none  w-[50vw] md:w-[80vw] my-2 p-2 "
                >
                  {cart.map((el: IReward, index: number) => {
                    return (
                      <SwiperSlide
                        style={{ minWidth: "250px" }}
                        key={index}
                        className=" rounded w-40 md:w-80 h-90 relative border-[1px] my-2  border-[#202020] flex items-center bg-[#0f0f0f] justify-between p-2 cursor-pointer hover:border-gray-600 transition-all mx-3 cart-swiper"
                      >
                        <div className="w-[30%] h-auto md:w-16 md:h-16 flex items-center justify-center">
                          <img
                            width="100%"
                            height="100%"
                            alt={el.title}
                            src={el.image}
                          />
                        </div>
                        <h1 className="w-[65%] max-text-line-2 text-sm">
                          {el.title}
                        </h1>
                        <button
                          onClick={() => {
                            deleteItem(el.id, el.points);
                          }}
                          className="absolute -top-[8px] -right-[10px] rounded-full bg-white  hover:bg-slate-100 flex items-center justify-center w-6 h-6 transition-all"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-black"
                          />
                        </button>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

                <div className=" md:w-[15vw]  flex items-center  ">
                  <button
                    onClick={redeemRewards}
                    className="bg-[#6360FF] text-white py-1 px-4 font-normal rounded m-2 hover:bg-transparent border border-[#6360FF]"
                  >
                    Redeem now
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-full flex items-center justify-center"></div>
          )}
          {view.status ? (
            <Modal
              onClose={() =>
                setView({
                  status: false,
                  value: view.value,
                  isInCart: view.isInCart,
                })
              }
              open={view.status}
            >
              <div className="w-3/4 h-3/4 bg-[#333333] text-[#FAFAFA] rounded-lg drop-shadow">
                <div
                  style={{ height: "calc(100% - 75px)" }}
                  className="w-full flex flex-col md:flex-row  items-start justify-between p-3"
                >
                  <div className="w-full md:w-2/4 h-2/4 md:h-full p-8 overflow-hidden my-1 flex items-center justify-center">
                    <img
                      className="group-hover:scale-110 transition-all inline-block rounded-sm"
                      width="75%"
                      height="75%"
                      src={view.value.image}
                      alt={view.value.title}
                    />
                  </div>
                  <div className="w-full md:w-2/4 h-2/4 md:h-full overflow-y-auto p-2">
                    <h2 className="text-xl font-semibold mt-10 mb-2">
                      {rewards[view?.value]?.title}
                    </h2>
                    <h3 className="text-base font-medium text-gray-400 my-2">
                      Brand:
                      <span className="text-[#FAFAFA]">{view.value.brand}</span>
                    </h3>
                    <div className="my-3 text-lg font-medium">
                      <FontAwesomeIcon color="#E1B530" icon={faCoins} />
                      <span className="mx-2">{view.value.points}</span>
                    </div>
                    <button
                      className={`transition-all rounded bg-[#6360FF] text-white px-3 py-1 my-4 font-medium`}
                      onClick={() =>
                        view.isInCart
                          ? deleteItem(view.value.id, view.value.points)
                          : updateCart(view.value.id, view.value.points)
                      }
                    >
                      {view.isInCart ? "Remove reward" : "Select reward"}
                    </button>
                    <div className={`text-base mt-4 my-2`}>
                      {htmlParser(view.value.description)}
                    </div>
                    <div className="my-2">
                      {view.value.features
                        .filter((el: any) => el.key !== "Brand")
                        .map((el: any, index: number) => (
                          <div key={index} className="grid grid-cols-3">
                            <div className="text-gray-400">{el.key}</div>
                            <div className="col-span-2">{el.value}</div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center justify-end px-4">
                  <button
                    onClick={() =>
                      setView({
                        status: false,
                        value: view.value,
                        isInCart: view.isInCart,
                      })
                    }
                    className="transition-all rounded bg-[#6360FF] text-white px-3 py-1 my-4 font-medium"
                  >
                    Done
                  </button>
                </div>
              </div>
            </Modal>
          ) : (
            <></>
          )}
        </main>
      )}
      {modalOpen && errorMessage.status ? (
        <Modal open={modalOpen}>
          <div className="w-80 h-48 bg-white rounded-lg drop-shadow px-4 pt-10 flex flex-col items-center ">
            <div>
              <h3 className="text-lg font-medium text-center">
                {errorMessage?.message}
              </h3>
            </div>
            <div className="w-full flex item-center text-white justify-evenly mt-8">
              <button
                className=" w-32 py-2 bg-[#6360FF] rounded"
                onClick={() => navigate("/checkout")}
              >
                Redeem Now
              </button>
              <button
                onClick={() => setModalOpen(false)}
                className="bg-[#6360FF] w-32 text-white rounded py-2   "
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {/* <div className={`w-full bg-white fixed bottom-0 right-0 transition-all duration-300 overflow-hidden ${cart.length > 0 ? 'h-36 opacity-100 p-4' : 'h-0 opacity-0 p-0'}`}>
        
      </div> */}
    </>
  );
};

export default Home;

// var swiper = new Swiper(".swiper-container", {
//   loopedSlides: 8,
//   loop: true,
//   slidesPerView: "auto",
//   freeMode: true,
//   mousewheel: {
//     releaseOnEdges: true,
//   },
// });
