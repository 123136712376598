import {
  faCoins,
  faEye,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { IReward } from "../../interfaces/IRewards";

const getSessionStorage = () => {
  let cart = sessionStorage.getItem("cart");
  if (cart) {
    let newCart: any;
    newCart = sessionStorage.getItem("cart");
    return JSON.parse(newCart);
  } else {
    return [];
  }
};
const Reward = (props: {
  data: IReward;
  onViewClick: any;
  addToCart: any;
  isInCart: boolean;
}) => {

  return (
    <div
      className="group w-full  bg-[#0f0f0f] border-[1.5px] border-[#202020] rounded transition-all  shadow-lg hover:shadow hover:shadow-slate-900/10 flex flex-col items-start justify-between h-[22rem]  "
    >
      <div className="h-[270px] w-full">
        <div
          className="w-full h-[75%] rounded-t overflow-hidden bg-white my-1 flex items-center justify-center cursor-pointer  "
          onClick={() => props.onViewClick()}
        >
          <div className="w-full h-full flex items-center justify-center">
            <img
              className=" transition-all inline-block object-cover "
              src={props.data.image}
              alt={props.data.title}
              style={{
                width: `${(1000 / 10) * 2}px`,
                height: `${(1000 / 10) * 2}px`,
              }}
            />
          </div>
        </div>
        <div
          className="cursor-pointer w-full p-2"
          onClick={() => props.onViewClick()}
        >
          <div className="w-full flex items-center justify-between">
            <h3 className="text-sm whitespace-nowrap text-[#535766] font-medium my-1">
              {props.data.brand}
            </h3>
            <div className="my-1 text-sm font-semibold">
              <FontAwesomeIcon color="#E1B530" icon={faCoins} />
              <span className="mx-2">{props.data.points}</span>
            </div>
          </div>
          <h1 className="reward-title  text-ellipsis mt-1.5 overflow-hidden font-normal text-xs text-[#FAFAFA]">
            {props.data.title}
          </h1>
        </div>
      </div>

      <div className=" w-56 mx-auto ">
        <button
          className={`${
              props.isInCart
              ? "opacity-0 pointer-events-none"
              : "opacity-100 pointer-events-auto"
            } transition-all w-full rounded bg-[#6360FF] text-white py-2 my-2 font-semibold text-sm hover:bg-transparent border border-[#6360FF]`}
          onClick={() => {
            props.addToCart();
          }}
        >
          <FontAwesomeIcon icon={faShoppingCart} />
          <span className="mx-2 font-normal">Add to Cart</span>
        </button>
      </div>
    </div>
  );
};

export default Reward;
