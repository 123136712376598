import React from "react";
import { footerData } from "../utils/data";
import { FaFacebookF, FaTwitter, FaGooglePlay, FaApple } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className=" flex flex-col  xl:flex-row  bg-navColor font-medium text-gray-300 px-14 justify-between py-10">
      {/* left  */}
      <div className=" space-y-3  w-fit ">
        <ul className=" flex space-x-4 w-full">
          {footerData.map((item) => {
            return (
              <li className="hover:text-[#6360FF] cursor-pointer">
                {item.name}
              </li>
            );
          })}
        </ul>
        <div className=" w-[35rem] text-sm">
          <p>
            &copy; 2022 STAR. All Rights Reserved. HBO, Home Box Office and all
            related channel and programming logos are service marks of, and all
            related programming visuals and elements are the property of, Home
            Box Office, Inc. All rights reserved
          </p>
        </div>
      </div>
      
      {/* middle  */}

<div className=" xl:w-1/2 flex  justify-start mt-4 xl:mt-0 xl:justify-between space-x-10 ">

      <div className="  lg:my-0 space-y-2 text-gray-400">
        <h2>Connect with us</h2>
        <div className="flex justify-start  lg:justify-center text-white space-x-4">
          <FaFacebookF className=" text-4xl  bg-cardColor hover:bg-[#6360FF] cursor-pointer rounded-md p-2 " />
          <FaTwitter className=" text-4xl bg-cardColor hover:bg-[#6360FF] cursor-pointer rounded-md p-2 " />
        </div>
      </div>

      {/* right  */}
      <div className=" text-gray-400 space-y-2 ">
        <h2>Accoladez App</h2>
        <div className="flex space-x-2">
          <div className="flex items-center space-x-2 bg-cardColor hover:bg-[#6360FF] hover:text-white cursor-pointer  px-3 rounded-md py-1">
            <FaGooglePlay className="text-4xl text-white" />
            <div>
              <h3 className="text-xs">Get it on</h3>
              <h3 className="text-xl">Google Play</h3>
            </div>
          </div>
          <div className=" flex items-center space-x-2 bg-cardColor hover:bg-[#6360FF] hover:text-white cursor-pointer px-3 rounded-md ">
            <FaApple className="text-4xl text-white" />
            <div>
              <h3 className="text-xs">Get it on</h3>
              <h3 className="text-xl">Play Store</h3>
            </div>
          </div>
        </div>
      </div>
      {/* right ends  */}
</div>
    </footer>
  );
};

export default Footer;
