import React from "react";
import { navData } from "../utils/data";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate()
  return (
    <section className=" bg-navColor  z-50 w-screen overflow-hidden fixed top-0 flex items-center h-20 justify-between text-gray-300 px-14 text-xl transition-all ">
      {/* left side  */}
      <nav className="flex items-center space-x-4 ">
        <div>
          <GiHamburgerMenu className="text-2xl hover:text-white cursor-pointer duration-200 " />
        </div>
        <div className="font-bold  tracking-wide cursor-pointer px-5">Accoladez </div>
        <ul className="hidden lg:flex md:flex space-x-7   ">
          {navData.map((item) => {
            const { name, url } = item;
            return (
              <li className="hover:text-white duration-200 cursor-pointer">
                {name}
              </li>
            );
          })}
        </ul>
      </nav>
      {/* right side  */}
      <div className="flex space-x-10 ">
        <form className=" hidden  lg:block relative  lg:ml-6">
          <input
            type="text"
            placeholder="Search"
            className=" outline-none w-72 border-b-2 border-gray-300 pb-1 bg-transparent"
          />
          <AiOutlineSearch className="absolute right-0 top-1 text-gray-500 cursor-pointer" />
        </form>
        <div className="flex space-x-5">
          <button onClick={()=>navigate("/subscribe")} className="rounded px-4 text-white text-sm bg-[#6360FF] font-medium">SUBSCRIBE</button>
          <button className="font-semibold tracking-wide text-base px-2">LOGIN</button>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
