import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { routes } from '../constants/routes';
import Checkout from '../pages/Checkout';
import Home from '../pages/Home';
import Subscription from '../pages/Subscription';
import DisneyHome from "../pages/DisneyHome"
const RouterConfig = () => {
  return (
    <Routes>
      <Route path={`${routes.disney}`} element={<DisneyHome/>}/>
      <Route path={`${routes.home}`} element={<Home />}/>
      <Route path={`${routes.subscription}`} element={<Subscription/>}/>
      <Route path={`${routes.checkout}`} element={<Checkout/>}/>
    </Routes>
  )
}

export default RouterConfig;