import React from "react";
import Hero from "../components/Hero";
import ScrollSection from "../components/ScrollSection";
import { trending,popularShow,recommendedMovies } from "../utils/data";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const sections = [
  {data:trending,title:"Latest & Trending"},
  {data:popularShow,title:"Popular Shows"},
  {data:recommendedMovies,title:"Movies Recommended For You"},
]
const Home = () => {
  return (
    <>
    <Navbar />
    <main className="mt-[calc(100rem-95rem)]">
    <div className=" text-white bg-navColor py-3  ">
      <Hero />
      {sections.map((item,i)=>{
     const {data,title} = item
       return <ScrollSection key={i} data={data} title={title}/>
      })}
    </div>
      <Footer />
    </main>
  </>
  );
};

export default Home;
