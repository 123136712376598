import axios from "axios"
import { env } from "../../../constants/env";

export const getRewards = async (page: number = 1, filters?: {}) => {
  try {
    const data = await axios.get(`${env.BASE_URL}abs/client/catalogue?page=${page}`,{
      headers: {
        "X-API-KEY": env.X_API_KEY,
        "X-API-SECRET": env.X_API_SECRET
      }
    });
    return data
  } catch (e) {
    throw e;
  }
}

export const getFilters = async () => {
  try {
    const data = await axios.get(`${env.BASE_URL}abs/client/catalogue/filter`, {
      headers: {
        "x-api-key": env.X_API_KEY,
        "x-api-secret": env.X_API_SECRET
      }
    });

    return data
  } catch (e) {
    throw e
  }
}

export const addReward = async (reward_id: number) => {
  try {
    const data = await axios.patch(`${env.BASE_URL}/client/store/reward/${reward_id}/add`, {}, {
      headers: {
        "x-api-key": env.X_API_KEY,
        "x-api-secret": env.X_API_SECRET
      }
    })
    return data
  } catch (e) {
    throw e
  }
}

export const removeReward = async (reward_id: number) => {
  try {
    const data = await axios.patch(`${env.BASE_URL}/client/store/reward/${reward_id}/remove`, {}, {
      headers: {
        "x-api-key": env.X_API_KEY,
        "x-api-secret": env.X_API_SECRET
      }
    })
    return data
  } catch (e) {
    throw e
  }
}

