import React from 'react';
import './App.css';
import { BrowserView, MobileView } from 'react-device-detect';

//routes
import { BrowserRouter as Router } from 'react-router-dom';
import RouterConfig from './routes/RouterConfig';
import Navbar from './components/navigation/Navbar';

function App() {


  return (
    <div className='bg-[#050505] overflow-x-hidden '>
      <BrowserView>
        <Router>
          <RouterConfig />
        </Router>
      </BrowserView>
      <MobileView>
        <div className='text-center  h-[100vh] text-white text-2xl flex items-center justify-center'><p className=' my-auto'> This page is not supported</p></div>
      </MobileView>
    </div>
  );
}

export default App;
